<template>
  <div class="pdf">
    <el-page-header @back="$router.back()" content="详情页面">
    </el-page-header>
    <el-button-group>
      <el-button type="primary" icon="el-icon-arrow-left" @click.native="prePage">上一页</el-button>
      <el-button type="primary" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
    </el-button-group>
    <div style="marginTop: 10px; color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
    <pdf
        :page="pageNum"
        :src="url"
        @progress="loadedRatio = $event"
        @num-pages="pageTotalNum=$event"
    ></pdf>
    <el-button-group>
      <el-button type="primary" icon="el-icon-arrow-left" @click.native="prePage">上一页</el-button>
      <el-button type="primary" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
    </el-button-group>
<!--    <pdf-->
<!--        ref="pdf"-->
<!--        :src="url"-->
<!--    >-->
<!--    </pdf>-->
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: "my-pdf",
  components: {
    pdf
  },
  // props: ["url"],
  data() {
    return {
      // url: "https://fakerlove.oss-cn-beijing.aliyuncs.com/kaoyan/2022/02/25/ed2c69ff331d4e6e8114b60665faedb9翻译.pdf",
      url: this.$route.query.url,
      pageNum: 1,
      pageTotalNum: 1, //总页数
      loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
    }
  },
  mounted() {
    this.getNumPages()
  },
  methods: {
    // 上一页
    prePage() {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },

    // 下一页
    nextPage() {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    // 获取页数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url)
      loadingTask.promise.then(pdf => {
        this.pageTotalNum = pdf.numPages
      }).catch(err => {
        console.log('pdf 加载失败', err);
      })
    }
  }
}
</script>

<style scoped>
.pdf{
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
}
</style>